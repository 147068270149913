<template>
  <div class="bg-white h-screen">
    <trac-loading v-if="isLoading"></trac-loading>
    <div class="w-full flex flex-wrap">
      <!-- Login Section -->
      <div class="w-full md:w-1/2 flex flex-col">
        <div
          class="flex justify-center items-center md:justify-start pt-12 md:pl-12 md:-mb-24"
        >
          <!-- <a href="#" class="bg-black text-white font-bold text-xl p-4">Logo</a> -->
          <img
            class="rounded-full"
            src="../assets/logo.png"
            alt="Traction Logo"
          /><span class="text-2xl pl-2">TractionAdmin</span>
        </div>

        <div
          class="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32"
        >
          <p class="text-center text-3xl">Welcome.</p>
          <form class="flex flex-col pt-3 md:pt-8" @submit.prevent="logUserIn">
            <div class="flex flex-col pt-4">
              <label for="email" class="text-xs">Email</label>
              <input
                type="email"
                id="email"
                v-model="loginDetails.email"
                placeholder="your@email.com"
                class="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div class="flex flex-col pt-4">
              <label for="password" class="text-xs">Password</label>
              <input
                type="password"
                id="password"
                v-model="loginDetails.password"
                placeholder="Password"
                class="shadow appearance-none border text-xs rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <button
              type="submit"
              class="bg-gray-800 text-white font-bold text-xs hover:bg-gray-700 p-2 mt-8"
            >
              Log In
            </button>
          </form>
          <div class="text-center pt-12 pb-12">
            <p>
              Forgot Password?
              <router-link to="reset-password" class="underline font-semibold"
                >Click here.</router-link
              >
            </p>
            <p class="text-xs">
              TractionApps admin platform for managing payments for clientele
              and other logs.
            </p>
          </div>
        </div>
      </div>

      <!-- Image Section -->
      <div class="w-1/2 shadow-2xl">
        <img
          class="object-cover w-full h-screen hidden md:block"
          src="https://source.unsplash.com/IXUM4cJynP0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { SAVE_LOCAL_DB_DATA } from "../browser-db-config/localStorage";

export default {
  data() {
    return {
      isLoading: false,
      loginDetails: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async logUserIn() {
      this.isLoading = true;
      await this.$store.dispatch("LOG_USER_IN", this.loginDetails);
      const res = this.$store.getters["GET_LOGIN_RES"];

      if (res.status) {
        this.saveData("traction-app-user-data", res.data);
        await this.fetchBusinessUserDetails();
        this.$router.push({ name: "QuickAccess" });
      } else {
        alert(res.message || "Error: Netowrk error occured.");
      }
      this.isLoading = false;
    },
    async fetchBusinessUserDetails() {
      await this.$store.dispatch("FETCH_BUSINESS_USER_DETAILS");
      const res = this.$store.getters["GET_BUSINESS_USER_RES"];

      if (res.status) {
        this.saveData("traction-app-current-user-business-data", res.data);
        // this.$router.push({ name: "QuickAccess" });
        // console.log(res.res)
      }
    },
    saveData(localStorageName, dataToStore) {
      SAVE_LOCAL_DB_DATA(localStorageName, dataToStore);
    },
  },
};
</script>

<style></style>
